import React, { useContext, useEffect, useState } from "react";
import { FixedSizeList } from "react-window";
import { CustomAlert } from "../CustomAlert/CustomAlert";
import { getAllPreMatriculas, setAllContracts } from "../../api/sponteAPI";
import RegistrationItem from "../RegistrationItem";
import { aulas_inicio } from "../../utils/YEARCONSTS";
import { Button } from "@mui/material";
import { Loading } from "../Loading/Loading";
import { AuthContext } from "../../contexts/Auth/AuthContext";

export const RegistrationStats = () => {
  const { user } = useContext(AuthContext);
  const [registration, setRegistration] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [infoType, setInfoType] = useState("success");
  const [loading, setLoading] = useState(false);

  const updateRegistrationList = () => {
    setLoading(true);
    getAllPreMatriculas()
      .then((res) => {
        setRegistration(res);
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    updateRegistrationList();
  }, []);

  const Row = ({ index, style }) => (
    <div style={style}>
      <RegistrationItem data={registration[index]} />
    </div>
  );

  const setAllRegistrations = (SituacaoID) => {
    setLoading(true);
    const promises = registration.map((register) => {
      return setAllContracts(register.ContratoID, SituacaoID);
    });

    Promise.all(promises)
      .then((results) => {
        setLoading(false);
        setInfoMessage("Todos os registros foram alterados com sucesso!");
        setShowMessage(true);
        updateRegistrationList();
      })
      .catch((error) => {
        setLoading(false);
        setInfoType("error");
        setInfoMessage(
          "Houve uma falha ao alterar os registros tente novamente!"
        );
        setShowMessage(true);
        updateRegistrationList();
      });
  };

  return (
    <>
      {loading && <Loading />}
      <span>
        Total de Matrículas {aulas_inicio.anoLetivo}:{" "}
        <strong>{registration.length}</strong>
      </span>
      <br></br>
      {(user.level === "admin") &&
      (
        <div style={{ margin: 20 }}>
          <Button
            color="success"
            style={{ marginRight: 10 }}
            variant="outlined"
            onClick={() => setAllRegistrations(1)}
          >
            Tornar todas as matrículas ativas
          </Button>
          <Button
            color="error"
            variant="outlined"
            onClick={() => setAllRegistrations(6)}
          >
            Tornar todas as matrículas pendentes
          </Button>
        </div>
      )}
      <FixedSizeList
        style={{ margin: "auto" }}
        height={400} // Altura da lista
        width="90%" // Largura da lista
        itemSize={52} // Altura de cada item
        itemCount={registration.length} // Número total de itens
      >
        {Row}
      </FixedSizeList>
      <CustomAlert
        infoType={infoType}
        status={showMessage}
        setStatus={setShowMessage}
        infoMessage={infoMessage}
      />
    </>
  );
};
